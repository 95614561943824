import React from 'react';
// import _ from 'lodash';

// reactstrap components
import { Row, Container } from 'reactstrap';
import { Query } from 'react-apollo';
import { 
  PrisonierByRegion,
  PrisonierByPrefecture,
  PrisonierByPrison,
  ChefAccusation,
  BesoinsChart,
  NourrituresChart
} from '../detenus/prisonier_charts';

import {
  PRISONIER_AGGREGATED_BY_REGION,
  PRISONIER_AGGREGATED_BY_PREFECTURE,
  PRISONIER_AGGREGATED_BY_PRISON,
  CHEF_ACCUSATION,
  NOURRITURES,
  BESOINS
} from '../../gql/detenus';

const Reports = () => {

  return (
    <>
      <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
        <Container fluid>
        </Container>
        <Container fluid>
          <Query
            query={PRISONIER_AGGREGATED_BY_REGION}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <Row>
                  <PrisonierByRegion data={data?.prisonierAggregatedByRegion} />
                </Row>
              );
            }}
          </Query>
          <Query
            query={PRISONIER_AGGREGATED_BY_PREFECTURE}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <Row>
                  <PrisonierByPrefecture data={data?.prisonierAggregatedByPrefecture} />
                </Row>
              );
            }}
          </Query>

          <Query
            query={PRISONIER_AGGREGATED_BY_PRISON}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                // <Row>
                  <PrisonierByPrison data={data?.prisonierAggregatedByPrison} />
                // </Row>
              );
            }}
          </Query>

          <Query
            query={CHEF_ACCUSATION}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                // <Row>
                  <ChefAccusation data={data?.chefAccusation} />
                // </Row>
              );
            }}
          </Query>

          <Query
            query={BESOINS}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return <BesoinsChart data={data?.besoin} />
              
            }}
          </Query>

          <Query
            query={NOURRITURES}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return  <NourrituresChart data={data?.nourriture} />
            }}
          </Query>



        </Container>
      </div>
    </>
  );
};

export default Reports;
