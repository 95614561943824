import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from 'reactstrap';
import ProgressBar from "@ramonak/react-progress-bar";

const Overview = ({ globalData }) => {

  let percentage = 0
  if (globalData?.totalFemmeEtMineur && globalData?.totalPrisonier) {
    percentage = parseFloat(((globalData?.totalFemmeEtMineur / globalData?.totalPrisonier) * 100).toFixed(2))
  }

  // console.log('Percentage: ', percentage)

  return (
    <>
      <Row className="align-items-center">
        <Col md="12" lg="12" xl="12">
          <Card className="card-stats mb-4">
            <CardBody>
              <Row className="align-items-center justify-content-center">
                <Col md="12">
                    <CardTitle tag="h1" className="text-center">
                      Progression de la collecte
                    </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Row className="justify-content-center align-items-center">
                    <ProgressBar 
                      maxCompleted={100} 
                      completed={percentage}
                      width='600px'
                      height='20'
                    />
                  </Row>
                </Col>
              </Row>
            </CardBody>
            </Card>
        </Col>
      </Row>
    </>
  );
};

export default Overview;
