import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';

exporting(Highcharts);
exportData(Highcharts);

const charMinHeigth = '1200'

export const SuiviByPrison = (props) => {
  useEffect(() => {
    const categories = []
    const seriesData = [
      { name: 'Total detenus declaré', data: [] },
      { name: 'Total femme et mineur declaré', data: [] },
      { name: 'Total Collecté', data: [] },
      { name: 'Total femme et mineur collecté', data: [] },

    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.prison)

        seriesData[0].data.push(item.totalDeclared)
        seriesData[1].data.push(item.totalFemmesEtMineursDeclared)
        seriesData[2].data.push(item.totalCollected)
        seriesData[3].data.push(item.totalFemmeEtMineursCollected)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'column'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        column: {
          dataLabels: {
            enabled: true,
            inside: true,
            rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Suivi de la collecte (Par prison)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      },
      data: {
        table: 'datatable'
      }
    };

    // Render the chart
    if (Highcharts) {
      Highcharts.chart('suivi_by_prison_chart', options);
    }
    // Highcharts.chart('suivi_by_prison_chart', options);
    // return () => chart.destroy();

  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      // tag={Link}
      // to={`/admin/single-territory/${territory.territoryAbbreviation}`}
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="suivi_by_prison_chart"
                style={{
                  minHeight: `600px`
                }}
              />
              <div id="datatable" />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};


export const PrisonByRegion = (props) => {
  useEffect(() => {
    // exporting(Highcharts);

    const categories = []
    const seriesData = [
      { name: 'Total Personnel', data: [] },
      { name: 'Total femme (personel)', data: [] },
      { name: 'Total detenus', data: [] },
      { name: 'Total femmes detenues', data: [] },
      { name: 'Total mineurs detenus', data: [] },
    ];

    if (props?.data) {
      // console.log('-=====data=====---')
      // console.log(props.data)
      props.data.forEach(item => {
        categories.push(item.region);

        seriesData[0].data.push(item.totalPersonnel)
        seriesData[1].data.push(item.totalFemme)
        seriesData[2].data.push(item.totalDetenus)
        seriesData[3].data.push(item.totalFemmeDetenus)
        seriesData[4].data.push(item.totalMineursDetenus)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Donnees des centres de detentions (par Region)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      },
      data: {
        table: 'datatable'
      }
    };

    // Render the chart
    Highcharts.chart('prison_by_region_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      // tag={Link}
      // to={`/admin/single-territory/${territory.territoryAbbreviation}`}
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="prison_by_region_chart"
                style={{
                  minHeight: `700px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export const PrisonByPrefecture = (props) => {

  useEffect(() => {

    const categories = []
    const seriesData = [
      { name: 'Total Personnel', data: [] },
      { name: 'Total femme (personel)', data: [] },
      { name: 'Total detenus', data: [] },
      { name: 'Total femmes detenues', data: [] },
      { name: 'Total mineurs detenus', data: [] },
    ];



    if (props?.data) {
      // console.log('-=====data=====---')
      // console.log(props.data)
      props.data.forEach(item => {
        categories.push(item.prefecture);

        seriesData[0].data.push(item.totalPersonnel)
        seriesData[1].data.push(item.totalFemme)
        seriesData[2].data.push(item.totalDetenus)
        seriesData[3].data.push(item.totalFemmeDetenus)
        seriesData[4].data.push(item.totalMineursDetenus)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Donnees des centres de detentions (par Prefecture)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      },
      data: {
        table: 'datatable'
      }
    };

    // Render the chart
    Highcharts.chart('prison_by_prefecture_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="prison_by_prefecture_chart"
                style={{
                  minHeight: `900px`
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

