/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
// nodejs library to set properties for components
import { PropTypes } from 'prop-types'

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

var ps;

import { decrypt } from '../../utils/utils-2';
import { group_onvbg } from '../../constant'

// console.log('group_onvbg: ', group_onvbg)

class AdminSidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    // this.state = { userCountry: decrypt(localStorage.getItem('country')) || '' };
  }

  componentDidMount() {
    // this.pollingInterval = setInterval(() => {
    //   const currentuserCountry = decrypt(localStorage.getItem('country'));
    //   if (currentuserCountry !== this.state.userCountry) {
    //     this.setState({ userCountry: currentuserCountry });
    //   }
    // }, 1000); // Check every second
  }

  componentWillUnmount() {
    // clearInterval(this.pollingInterval);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }

    const logout = () => {
      localStorage.removeItem('token');
      // Remove the user email as well
      localStorage.removeItem('userEmail');
      localStorage.removeItem('isStaff');
      localStorage.removeItem('isSuperuser');
      localStorage.removeItem('country');
      localStorage.removeItem('role');
      props.history.push('/login');
    };

    // Find username
    const getUsername = (email) => {
      if (!email) {
        return 'Welcome';
      }
      let emailWithoutDot = email.split('.')[0];
      const dotSplit = emailWithoutDot.split('.');
      if (dotSplit.length >= 2) {
        return dotSplit[0];
      }
      const atSplit = emailWithoutDot.split('@');
      if (atSplit.length) {
        return atSplit[0];
      }
      return 'Welcome';
    };

    const userGroups = JSON.parse(localStorage.getItem('groups'));

    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white pt-2 pb-1"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center flex-column">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require('../../assets/img/profile/default-avatar.png')}
                    />
                  </span>
                  <small>
                    <strong>
                      {getUsername(localStorage.getItem('userEmail'))}
                    </strong>
                  </small>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem to="/login" tag={Link}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            {/* <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div> */}
            {/* Form */}
            {/* <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form> */}
            {/* Navigation */}
            {/*<Nav navbar>{this.createLinks(routes)}</Nav>*/}
            <Nav navbar>
              <NavItem>
                <NavLink
                  to="/admin/cartographie/index"
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  <i className="fas fa-map" />
                  Carte
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/cartographie/rapport"
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active"
                >
                  {/* <i className="fas fa-store-alt" /> */}
                  <i className="fas fa-users" />
                  Cartographie
                </NavLink>
              </NavItem>

              {userGroups && userGroups.includes(group_onvbg) && (
                <>
                 <NavItem>
                  <NavLink
                    to="/admin/detenus/index"
                    tag={NavLinkRRD}
                    onClick={this.closeCollapse}
                    activeClassName="active"
                  >
                    {/* <i className="fas fa-users" /> */}
                    <i className="fas fa-store-alt" />
                    Centres de detention
                  </NavLink>
               </NavItem>
               <NavItem>
                 <NavLink
                   to="/admin/detenus/rapport"
                   tag={NavLinkRRD}
                   onClick={this.closeCollapse}
                   activeClassName="active"
                 >
                   <i className="fas fa-users" />
                   Detenus
                 </NavLink>
               </NavItem>
               </>
              )}
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

AdminSidebar.defaultProps = {
  routes: [{}],
};

AdminSidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default AdminSidebar;
