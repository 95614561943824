import React from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';

import { Query } from 'react-apollo';
import {
  StructureByRegion,
  StructureByPrefecture,
  BeneficiaireByRegion,
  BeneficiaireByPrefecture,
} from './structure_charts';

import {
  STRUCTURE_AGGREGATED_BY_REGION,
  STRUCTURE_AGGREGATED_BY_PREFECTURE,
  BENEFICIAIRE_BY_REGION,
  BENEFICIAIRE_BY_PREFECTURE
} from '../../gql/cartographie';


import { group_onvbg } from '../../constant'

const Reports = () => {

  const userGroups = JSON.parse(localStorage.getItem('groups'));

  return (
    <>
      <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
        <Container fluid>
        </Container>
        <Container fluid>
          <Query
            query={STRUCTURE_AGGREGATED_BY_REGION}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <Row>
                  <StructureByRegion data={data?.structureAggregatedByRegion} />
                </Row>
              );
            }}
          </Query>

          <Query
            query={STRUCTURE_AGGREGATED_BY_PREFECTURE}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <Row>
                  <StructureByPrefecture data={data?.structureAggregatedByPrefecture} />
                </Row>
              );
            }}
          </Query>

          {/* Beneficiaires */}
          <Query
            query={BENEFICIAIRE_BY_REGION}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <Row>
                  <BeneficiaireByRegion data={data?.structureAggregatedByRegion} />
                </Row>
              );
            }}
          </Query>

          <Query
            query={BENEFICIAIRE_BY_PREFECTURE}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <Row>
                  <BeneficiaireByPrefecture data={data?.structureAggregatedByPrefecture} />
                </Row>
              );
            }}
          </Query>


          {userGroups && userGroups.includes(group_onvbg) && (
          <Col md="12" lg="12" xl="12">
            <Card
              className="card-stats mb-4"
            >
              <CardBody>
                <Row className="justify-content-between">
                  <Col md="12" className="justify-content-center align-items-center" >
                    <div className='justify-content-center align-items-center' style={{
                      alignSelf: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center'
                    }}>
                      <h3
                      >Acceder aux donnees brutes de l'enquete au format excel</h3>
                      <a
                        href='https://docs.google.com/spreadsheets/d/11epAyK4DeHQuy7Z8QycjjqfQhDCZsaIC/edit?usp=sharing&ouid=106305726816676692999&rtpof=true&sd=true'
                        alt='bouton telecharger'
                        className='btn'
                        target='_blank'
                      >
                        Cliquez sur ce lien
                      </a>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> 
          )}

        </Container>
      </div>
    </>
  );
}

export default Reports
