import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import world_cloud from 'highcharts/modules/wordcloud';
import exportData from 'highcharts/modules/export-data';

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';
exporting(Highcharts);
exportData(Highcharts);
world_cloud(Highcharts);

const charMinHeigth = '1200'

export const StructureByRegion = (props) => {

  useEffect(() => {

    const categories = []
    const seriesData = [
      { name: 'Total structures', data: [] },
      { name: 'Total ong internationale', data: [] },
      { name: 'Total ong nationale', data: [] },
      { name: 'Total gouvernementale', data: [] },
      { name: 'Total organisation à base communautaire/associative', data: [] },
      { name: 'Total confessionnelle', data: [] },
      { name: 'Total autre', data: [] },

    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.region);

        seriesData[0].data.push(item.totalStructures)
        seriesData[1].data.push(item.totalOngInternationale)
        seriesData[2].data.push(item.totalOngNationale)
        seriesData[3].data.push(item.totalGouvernementale)
        seriesData[4].data.push(item.totalOrganisationBaseCommunautaireAssoci)
        seriesData[5].data.push(item.totalOrganisationConfessionnelle)
        seriesData[6].data.push(item.totalAutre)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'column'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        },
        series: {
          // pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Données des structures (par Region et par Type)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('structure_by_region_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      // tag={Link}
      // to={`/admin/single-territory/${territory.territoryAbbreviation}`}
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="structure_by_region_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};


export const StructureByPrefecture = (props) => {

  useEffect(() => {
    const categories = []
    const seriesData = [
      { name: 'Total structures', data: [] },
      { name: 'Total ong internationale', data: [] },
      { name: 'Total ong nationale', data: [] },
      { name: 'Total gouvernementale', data: [] },
      { name: 'Total organisation à base communautaire/associative', data: [] },
      { name: 'Total confessionnelle', data: [] },
      { name: 'Total autre', data: [] },
    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.prefecture);

        seriesData[0].data.push(item.totalStructures)
        seriesData[1].data.push(item.totalOngInternationale)
        seriesData[2].data.push(item.totalOngNationale)
        seriesData[3].data.push(item.totalGouvernementale)
        seriesData[4].data.push(item.totalOrganisationBaseCommunautaireAssoci)
        seriesData[5].data.push(item.totalOrganisationConfessionnelle)
        seriesData[6].data.push(item.totalAutre)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Données des structures (par Prefecture et par Type)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('structure_by_prefecture_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="structure_by_prefecture_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};


export const BeneficiaireByRegion = (props) => {

  useEffect(() => {
    const categories = []
    const seriesData = [
      { name: 'Total Beneficiaire Année ecoulé', data: [] },
      { name: 'Total Beneficiare Estimé Année suivante', data: [] },

    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.region);

        seriesData[0].data.push(item.totalBeneficiaireAnneeEcoule)
        seriesData[1].data.push(item.totalBeneficiaireAnneeFuture)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'column'
      },
      plotOptions: {
        bar: {
          // pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        // column: {
        //   stacking: 'normal',
        //   dataLabels: {
        //     enabled: true
        //   }
        // },
        series: {
          // pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}'
      },
      // legend: {
      //   align: 'left',
      //   x: 70,
      //   verticalAlign: 'top',
      //   y: 70,
      //   floating: true,
      //   backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      //   borderColor: '#CCC',
      //   borderWidth: 1,
      //   shadow: false
      // },
      title: {
        text: 'Données relative aux bénéficiaires par Region (Année ecoulée vs année en cours)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        },
        stackLabels: {
          enabled: true
        },
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('beneficiaire_by_region_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      // tag={Link}
      // to={`/admin/single-territory/${territory.territoryAbbreviation}`}
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="beneficiaire_by_region_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};


export const BeneficiaireByPrefecture = (props) => {

  useEffect(() => {
    const categories = []
    const seriesData = [
      { name: 'Total Beneficiaire Année ecoulé', data: [] },
      { name: 'Total Beneficiare Estimé Année suivante', data: [] },
    ];

    if (props?.data) {
      
      // console.log('Data: ', props.data)
      props.data.forEach(item => {
        categories.push(item.prefecture);

        seriesData[0].data.push(item.totalBeneficiaireAnneeEcoule)
        seriesData[1].data.push(item.totalBeneficiaireAnneeFuture)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Données relative aux bénéficiaires par Prefecture (Année ecoulée vs année en cours)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('structure_by_prefecture_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="structure_by_prefecture_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
