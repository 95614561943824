import gql from 'graphql-tag';

export const GET_ALL_POINTS_DATA = gql`
  query {
    allPoints {
      nomOrganisation
      region
      prefecture
      nomEnquete
      titreEnquete
      phone
      email
      addresse
      coordonnees {
        longitude
        latitude
        altitude
        precision
      }
    }
  }
`;


// STRUCTURES
export const STRUCTURE_AGGREGATED_BY_REGION = gql`
  query {
    structureAggregatedByRegion {
      region
      totalStructures
      totalOngInternationale
      totalOngNationale
      totalGouvernementale
      totalOrganisationBaseCommunautaireAssoci
      totalOrganisationConfessionnelle
      totalAutre
      totalBeneficiaireAnneeEcoule
      totalBeneficiaireAnneeFuture
    }
  }
`;
export const STRUCTURE_AGGREGATED_BY_PREFECTURE = gql`
  query {
    structureAggregatedByPrefecture {
      region
      prefecture
      totalStructures
      totalOngInternationale
      totalOngNationale
      totalGouvernementale
      totalOrganisationBaseCommunautaireAssoci
      totalOrganisationConfessionnelle
      totalAutre
      totalBeneficiaireAnneeEcoule
      totalBeneficiaireAnneeFuture
    }
  }
`;


export const BENEFICIAIRE_BY_REGION = gql`
  query {
    structureAggregatedByRegion {
      region
      totalBeneficiaireAnneeEcoule
      totalBeneficiaireAnneeFuture
    }
  }
`;

export const BENEFICIAIRE_BY_PREFECTURE = gql`
  query {
    structureAggregatedByPrefecture {
      region
      prefecture
      totalBeneficiaireAnneeEcoule
      totalBeneficiaireAnneeFuture
    }
  }
`;
