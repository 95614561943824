import React, { useState, useEffect } from 'react';
import { Map as LeafletMap, TileLayer, Marker, Popup, GeoJSON, Tooltip, LayersControl, LayerGroup } from 'react-leaflet';
import { Icon } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../../src/styles/css/map.css';

import marquer from '../assets/img/icon/marquer-300x300.png';
import Admin1 from '../assets/resources/GIN_Admin1.json'
import Admin2 from '../assets/resources/GIN_Admin2.json'

const icon = new Icon({
    iconUrl: marquer,
    iconSize: [35, 35]
});

const MapsComponent = ({ allPoints }) => {
    const [facilities, setFacilities] = useState([]);

    const [selectedFacility, setSelectedFacility] = useState(null);
    const [activeOng, setActiveOng] = useState(null);
    const [showInfo, setShowInfo] = useState(true);
    const [activeBoundary, setActiveBoundary] = useState('Admin2');

    const [filters, setFilters] = useState({
        hfType: '',
        region: '',
        prefecture: '',
        driveDistance: ''
    });

    useEffect(() => {

    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const filteredFacilities = facilities.filter(facility => {
        // Apply filters here
        return true; // Replace with actual filter logic
    });

    const createClusterCustomIcon = (cluster) => {
        const count = cluster.getChildCount();
        let size = 'LargeXL';

        if (count < 10) {
            size = 'Small';
        }
        else if (count >= 10 && count < 100) {
            size = 'Medium';
        }
        else if (count >= 100 && count < 500) {
            size = 'Large';
        }
        const options = {
            cluster: `markerCluster${size}`,
        };

        return L.divIcon({
            html: `<div><span class="markerClusterLabel">${count}</span></div>`,
            className: `${options.cluster}`,
        });
    };

    const onEachFeature = (feature, layer) => {
        if (feature.properties && feature.properties.name) {
            layer.bindPopup(feature.properties.name);
        }
    }

    useEffect(() => {
    }, [])

    return (
        <div className="wrapper">
            <section>
                <div className="col-md-12">
                    <LeafletMap
                        center={
                            // [9.6412, -13.5784] // conakry
                            // [11.2864346,-10.7021921] // gaoual
                            [10.956346, -11.075941]
                        }
                        style={{
                            height: '900px',
                            borderRadius: '10px',
                        }}
                        zoom={8}

                        onzoom={(e) => {
                            const zoomLevel = e.target._zoom
                            // if (zoomLevel && zoomLevel >= 10) {
                            //     setActiveBoundary('Admin2')
                            // } else {
                            //     setActiveBoundary('Admin1')
                            // }
                        }}
                    >
                        <LayersControl position='topleft' >
                            <LayersControl.BaseLayer checked name="Open Street Map">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Google Map">
                                <TileLayer
                                    attribution="Google Maps"
                                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Google Map Satellite">
                                <LayerGroup>
                                    <TileLayer
                                        attribution="Google Maps Satellite"
                                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                                    />
                                </LayerGroup>
                            </LayersControl.BaseLayer>
                        </LayersControl>


                        <MarkerClusterGroup
                            // iconCreateFunction={createClusterCustomIcon}
                            spiderLegPolylineOptions={{
                                weight: 0,
                                opacity: 0,
                            }}
                        >
                            {activeBoundary && activeBoundary == 'Admin1' && (
                                <GeoJSON
                                    data={Admin1}
                                    onEachFeature={onEachFeature}
                                />
                            )}

                            {activeBoundary && activeBoundary == 'Admin2' && (
                                <GeoJSON
                                    data={Admin2}
                                    onEachFeature={onEachFeature}
                                />
                            )}

                            {allPoints && allPoints.map((elem, key) => {
                                return (
                                    <Marker
                                        key={`marker_${key}_${elem.coordonnees.longitude}`}
                                        position={[
                                            elem.coordonnees.longitude,
                                            elem.coordonnees.latitude
                                        ]}
                                        // title={elem.nomOrganisation}

                                        onClick={(e) => {
                                            setActiveOng(elem);
                                            setShowInfo(true)
                                        }}
                                        icon={icon}
                                    >
                                        <Tooltip direction='right' offset={[5, 2]} opacity={1} permanent>
                                            <span>{elem.nomOrganisation}</span>
                                        </Tooltip>
                                    </Marker>
                                )
                            }
                            )}
                        </MarkerClusterGroup>
                    </LeafletMap>
                    <div id="detailPosition">
                        <div className="box box-primary box-solid">
                            <div className="box-header with-border">
                                <h3 className="box-title">Details de l'organisation</h3>
                                <button
                                    className="btn btn-box-tool"
                                    id="btn-box-tool"
                                    data-widget="collapse"
                                    onClick={() => setShowInfo(!showInfo)}
                                >
                                    {showInfo ? <i className="fa fa-minus"></i> : <i className="fa fa-plus"></i>}
                                </button>


                            </div>

                            <div id="infoContent">
                                {activeOng && showInfo ? (
                                    <>
                                        <p><strong>Nom: </strong>{activeOng?.nomOrganisation}</p>
                                        <p><strong>Region: </strong>{activeOng?.region}</p>
                                        <p><strong>Prefecture: </strong>{activeOng?.prefecture}</p>
                                        <p><strong>Nom enqueté: </strong>{activeOng?.nomEnquete}</p>
                                        <p><strong>Titre enqueté: </strong>{activeOng?.titreEnquete}</p>
                                        <p><strong>Téléphone: </strong>{activeOng?.phone}</p>
                                        <p><strong>Email: </strong>{activeOng?.email}</p>
                                        <p><strong>Adresse: </strong>{activeOng?.addresse}</p>
                                    </>
                                ) : <p>
                                    <strong> Cliquez sur un point de la carte pour afficher les infos ici</strong>
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MapsComponent;
