import React from 'react';
import MapsComponent from '../../components/map';
import { Container } from 'reactstrap';
import {GET_ALL_POINTS_DATA} from '../../gql/cartographie'
import { Query } from 'react-apollo';

const Carte = () => {
  return (
    <>
      <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
        {/* <Container fluid> </Container> */}
        <Container fluid>

          <Query
            query={GET_ALL_POINTS_DATA}
            fetchPolicy={'network-only'}
          >
            {({ loading, error, data }) => {

              console.log('GOT DATA', data)


              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">
                    `{error.message}`
                  </p>
                );
              }

              return (
                <MapsComponent allPoints = {data.allPoints}/>
              );
            }}
          </Query>

        </Container>
      </div>
    </>
  );
};

export default Carte;
