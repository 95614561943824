/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import detenusIndex from '../pages/detenus/index.js'
import detenusReports from '../pages/detenus/reports.js'

import cartographieIndex from '../pages/cartographie/index.js'
import cartographieReports from '../pages/cartographie/reports.js'

const textPrimary = 'text-primary';

const routes = [
  {
    path: '/cartographie/index',
    name: 'Carte des ONG',
    icon: `ni ni-tv-2 ${textPrimary}`,
    component: cartographieIndex,
    layout: '/admin',
  },
  {
    path: '/cartographie/rapport',
    name: 'Rapport sur les ONG',
    icon: `ni ni-tv-2 ${textPrimary}`,
    component: cartographieReports,
    layout: '/admin',
  },
  {
    path: '/detenus/index',
    name: 'Rapport sur les centres de detention',
    icon: `ni ni-tv-2 ${textPrimary}`,
    component: detenusIndex,
    layout: '/admin',
  },
  {
    path: '/detenus/rapport',
    name: 'Rapport sur les detenus',
    icon: `ni ni-tv-2 ${textPrimary}`,
    component: detenusReports,
    layout: '/admin',
  },
];
export default routes;
