import React from 'react';
// import _ from 'lodash';

// reactstrap components
import { Row, Container } from 'reactstrap';
import { Query } from 'react-apollo';

import Overview from './overview';
import {PrisonByRegion, PrisonByPrefecture, SuiviByPrison} from './prison_charts';

import { GET_GLOBAL_DASHBOARD_DATA, GET_SUIVI_DATA, PRISON_AGGREGATED_BY_REGION, PRISON_AGGREGATED_BY_PREFECTURE } from '../../gql/detenus';

const Dashboard = () => {

  return (
    <>
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
          </Container>
          <Container fluid>
            <Query
              query={GET_GLOBAL_DASHBOARD_DATA}
              fetchPolicy={'network-only'}
            >
              {({ loading, error, data }) => {
                if (loading) return <p className="text-white">LOADING...</p>;
                if (error) {
                  console.log(error.message);
                  return (
                    <p className="text-white">
                      `{error.message}`
                    </p>
                  );
                }

                return (
                  <Overview
                    globalData={data?.globalData}
                  />
                );
              }}
            </Query>
            <Query
              query={GET_SUIVI_DATA}
              fetchPolicy={'network-only'}
            >
              {({ loading, error, data }) => {
                if (loading) return <p className="text-white">LOADING...</p>;
                if (error) {
                  console.log(error.message);
                  return (
                    <p className="text-white">
                      `{error.message}`
                    </p>
                  );
                }

                return (
                  <Row>
                    <SuiviByPrison  data={data?.suiviData}/>
                  </Row>
                );
              }}
            </Query>
            <Query
              query={PRISON_AGGREGATED_BY_REGION}
              fetchPolicy={'network-only'}
            >
              {({ loading, error, data }) => {
                if (loading) return <p className="text-white">LOADING...</p>;
                if (error) {
                  console.log(error.message);
                  return (
                    <p className="text-white">
                      `{error.message}`
                    </p>
                  );
                }

                return (
                  <Row>
                    <PrisonByRegion  data={data?.prisonAggragatedByRegion}/>
                  </Row>
                );
              }}
            </Query>
            <Query
              query={PRISON_AGGREGATED_BY_PREFECTURE}
              fetchPolicy={'network-only'}
            >
              {({ loading, error, data }) => {
                if (loading) return <p className="text-white">LOADING...</p>;
                if (error) {
                  console.log(error.message);
                  return (
                    <p className="text-white">
                      `{error.message}`
                    </p>
                  );
                }

                return (
                  <Row>
                    <PrisonByPrefecture  data={data?.prisonAggragatedByPrefecture}/>
                  </Row>
                );
              }}
            </Query>
          </Container>
        </div>
    </>
  );
};

export default Dashboard;
