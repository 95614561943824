import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import world_cloud from 'highcharts/modules/wordcloud';
import exportData from 'highcharts/modules/export-data';

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';
exporting(Highcharts);
exportData(Highcharts);
world_cloud(Highcharts);

const charMinHeigth = '1200'

export const PrisonierByRegion = (props) => {
  
  useEffect(() => {

    const categories = []
    const seriesData = [
      { name: 'Total detenus', data: [] },
      { name: 'Total femmes', data: [] },
      { name: 'Total mineurs', data: [] },
      { name: 'Total femmes mineurs', data: [] },
      { name: 'T.femmes mineurs mariées', data: [] },
      { name: 'T.femmes mineurs mariées maman', data: [] },
      { name: 'T. non juges', data: [] },
      { name: 'T. femmes non juges', data: [] },
      { name: 'T. mineurs non juges', data: [] },
      { name: 'T. femmes mineurs non juges', data: [] },
      { name: 'T. non condamnes', data: [] },
      { name: 'T. femmes non condamnees', data: [] },
      { name: 'T. femmes mineurs non condamnees', data: [] },
      { name: 'T. sans acces soins', data: [] },
      { name: 'T. sans access kits', data: [] },
      { name: 'T. sans avocats', data: [] },
      { name: 'T. sans acces visites', data: [] },
      { name: 'T. victimes violences', data: [] },
      { name: 'T. femmes victimes violences', data: [] },
      { name: 'T. mineurs victime violences ', data: [] },
      { name: 'T. femmes mineurs victimes violences ', data: [] },
    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.region);

        seriesData[0].data.push(item.totalPrisoniers)
        seriesData[1].data.push(item.totalFemmes)
        seriesData[2].data.push(item.totalMineurs)
        seriesData[3].data.push(item.totalFemmeMineurs)
        seriesData[4].data.push(item.totalFemmeMineurMaries)
        seriesData[5].data.push(item.totalFemmeMineurAyantEnfants)
        seriesData[6].data.push(item.totalNonJuges)
        seriesData[7].data.push(item.totalMineursNonJuges)
        seriesData[8].data.push(item.totalFemmeNonJuges)
        seriesData[9].data.push(item.totalFemmeMineursNonJuges)
        seriesData[10].data.push(item.totalNonCondamnes)
        seriesData[11].data.push(item.totalFemmeNonCondamnes)
        seriesData[12].data.push(item.totalFemmeMineursNonCondamnes)
        seriesData[13].data.push(item.totalNonAccessSoins)
        seriesData[14].data.push(item.totalNonAccessKits)
        seriesData[15].data.push(item.totalNonAccessAvocats)
        seriesData[16].data.push(item.totalNonAccessVisites)
        seriesData[17].data.push(item.totalVictimeViolences)
        seriesData[18].data.push(item.totalFemmeVictimeViolences)
        seriesData[19].data.push(item.totalMineursVictimeViolences)
        seriesData[20].data.push(item.totalFemmeMineursVictimeViolences)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Donnees des detenus (par Region)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('prisonier_by_region_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      // tag={Link}
      // to={`/admin/single-territory/${territory.territoryAbbreviation}`}
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="prisonier_by_region_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};


export const PrisonierByPrefecture = (props) => {

  useEffect(() => {
    const categories = []
    const seriesData = [
      { name: 'Total detenus', data: [] },
      { name: 'Total femmes', data: [] },
      { name: 'Total mineurs', data: [] },
      { name: 'Total femmes mineurs', data: [] },
      { name: 'T.femmes mineurs mariées', data: [] },
      { name: 'T.femmes mineurs mariées maman', data: [] },
      { name: 'T. non juges', data: [] },
      { name: 'T. femmes non juges', data: [] },
      { name: 'T. mineurs non juges', data: [] },
      { name: 'T. femmes mineurs non juges', data: [] },
      { name: 'T. non condamnes', data: [] },
      { name: 'T. femmes non condamnees', data: [] },
      { name: 'T. femmes mineurs non condamnees', data: [] },
      { name: 'T. sans acces soins', data: [] },
      { name: 'T. sans access kits', data: [] },
      { name: 'T. sans avocats', data: [] },
      { name: 'T. sans acces visites', data: [] },
      { name: 'T. victimes violences', data: [] },
      { name: 'T. femmes victimes violences', data: [] },
      { name: 'T. mineurs victime violences ', data: [] },
      { name: 'T. femmes mineurs victimes violences ', data: [] },
    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.prefecture);

        seriesData[0].data.push(item.totalPrisoniers)
        seriesData[1].data.push(item.totalFemmes)
        seriesData[2].data.push(item.totalMineurs)
        seriesData[3].data.push(item.totalFemmeMineurs)
        seriesData[4].data.push(item.totalFemmeMineurMaries)
        seriesData[5].data.push(item.totalFemmeMineurAyantEnfants)
        seriesData[6].data.push(item.totalNonJuges)
        seriesData[7].data.push(item.totalMineursNonJuges)
        seriesData[8].data.push(item.totalFemmeNonJuges)
        seriesData[9].data.push(item.totalFemmeMineursNonJuges)
        seriesData[10].data.push(item.totalNonCondamnes)
        seriesData[11].data.push(item.totalFemmeNonCondamnes)
        seriesData[12].data.push(item.totalFemmeMineursNonCondamnes)
        seriesData[13].data.push(item.totalNonAccessSoins)
        seriesData[14].data.push(item.totalNonAccessKits)
        seriesData[15].data.push(item.totalNonAccessAvocats)
        seriesData[16].data.push(item.totalNonAccessVisites)
        seriesData[17].data.push(item.totalVictimeViolences)
        seriesData[18].data.push(item.totalFemmeVictimeViolences)
        seriesData[19].data.push(item.totalMineursVictimeViolences)
        seriesData[20].data.push(item.totalFemmeMineursVictimeViolences)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Donnees des detenus (par Prefecture)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('prisonier_by_prefecture_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="prisonier_by_prefecture_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export const PrisonierByPrison = (props) => {

  useEffect(() => {
    const categories = []
    const seriesData = [
      { name: 'Total detenus', data: [] },
      { name: 'Total femmes', data: [] },
      { name: 'Total mineurs', data: [] },
      { name: 'Total femmes mineurs', data: [] },
      { name: 'T.femmes mineurs mariées', data: [] },
      { name: 'T.femmes mineurs mariées maman', data: [] },
      { name: 'T. non juges', data: [] },
      { name: 'T. femmes non juges', data: [] },
      { name: 'T. mineurs non juges', data: [] },
      { name: 'T. femmes mineurs non juges', data: [] },
      { name: 'T. non condamnes', data: [] },
      { name: 'T. femmes non condamnees', data: [] },
      { name: 'T. femmes mineurs non condamnees', data: [] },
      { name: 'T. sans acces soins', data: [] },
      { name: 'T. sans access kits', data: [] },
      { name: 'T. sans avocats', data: [] },
      { name: 'T. sans acces visites', data: [] },
      { name: 'T. victimes violences', data: [] },
      { name: 'T. femmes victimes violences', data: [] },
      { name: 'T. mineurs victime violences ', data: [] },
      { name: 'T. femmes mineurs victimes violences ', data: [] },
    ];

    if (props?.data) {
      props.data.forEach(item => {
        categories.push(item.prison);

        seriesData[0].data.push(item.totalPrisoniers)
        seriesData[1].data.push(item.totalFemmes)
        seriesData[2].data.push(item.totalMineurs)
        seriesData[3].data.push(item.totalFemmeMineurs)
        seriesData[4].data.push(item.totalFemmeMineurMaries)
        seriesData[5].data.push(item.totalFemmeMineurAyantEnfants)
        seriesData[6].data.push(item.totalNonJuges)
        seriesData[7].data.push(item.totalMineursNonJuges)
        seriesData[8].data.push(item.totalFemmeNonJuges)
        seriesData[9].data.push(item.totalFemmeMineursNonJuges)
        seriesData[10].data.push(item.totalNonCondamnes)
        seriesData[11].data.push(item.totalFemmeNonCondamnes)
        seriesData[12].data.push(item.totalFemmeMineursNonCondamnes)
        seriesData[13].data.push(item.totalNonAccessSoins)
        seriesData[14].data.push(item.totalNonAccessKits)
        seriesData[15].data.push(item.totalNonAccessAvocats)
        seriesData[16].data.push(item.totalNonAccessVisites)
        seriesData[17].data.push(item.totalVictimeViolences)
        seriesData[18].data.push(item.totalFemmeVictimeViolences)
        seriesData[19].data.push(item.totalMineursVictimeViolences)
        seriesData[20].data.push(item.totalFemmeMineursVictimeViolences)
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          pointWidth: 12, // Set the minimum width for the bars
          dataLabels: {
            enabled: true,
            inside: true,
            // rotation: -90
          }
        },
        series: {
          pointWidth: 12, // Set the width for all series
          pointPadding: 0.1, // Adjust the spacing between bars
          groupPadding: 0.2 // Adjust the spacing between groups of bars
        }
      },
      title: {
        text: 'Donnees des detenus (par centre de detention)'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'Total'
        }
      },
      series: seriesData,
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('prisonier_by_prison_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="prisonier_by_prison_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export const ChefAccusation = (props) => {
  useEffect(() => {
    const seriesData = [];

    if (props?.data) {
      props.data.forEach(item => {
        seriesData.push({
          name: item.name,
          weight: item.weight
        })
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      title: {
        text: "Chefs d'accusations"
      },
      series: [{
        type: 'wordcloud',
        data: seriesData
      }],
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('chef_accusation_chart', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="chef_accusation_chart"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export const BesoinsChart = (props) => {
  useEffect(() => {
    const seriesData = [];

    if (props?.data) {
      props.data.forEach(item => {
        seriesData.push({
          name: item.name,
          weight: item.weight
        })
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      title: {
        text: "Quels sont vos besoins ?"
      },
      series: [{
        type: 'wordcloud',
        data: seriesData
      }],
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('besoin_charts', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="besoin_charts"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export const NourrituresChart = (props) => {
  useEffect(() => {
    const seriesData = [];

    if (props?.data) {
      props.data.forEach(item => {
        seriesData.push({
          name: item.name,
          weight: item.weight
        })
      });
    }

    // console.log('-=====all data=====---')
    // console.log(props.data)
    // console.log(categories)
    // console.log(seriesData)

    const options = {
      title: {
        text: "Qu'est ce que vous manger "
      },
      series: [{
        type: 'wordcloud',
        data: seriesData
      }],
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadXLS',
              'downloadCSV',
              'viewFullscreen',
            ]
          }
        }
      }
    };

    // Render the chart
    Highcharts.chart('nourriture_charts', options);
  }, [props?.data])

  return (
    <Col md="12" lg="12" xl="12">
      <Card
        className="card-stats mb-4"
      >
        <CardBody>
          <Row className="justify-content-between">
            <Col md="12">
              <div
                id="nourriture_charts"
                style={{
                  minHeight: `${charMinHeigth}px`
                }}
              />;
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

