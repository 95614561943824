import gql from 'graphql-tag';

export const GET_GLOBAL_DASHBOARD_DATA = gql`
  query {
    globalData {
      totalPrison
      totalPrisonier
      overrolePrisoner
      totalFemmeEtMineur
    }
  }
`;

export const GET_SUIVI_DATA = gql`
  query {
    suiviData {
      prison
      totalDeclared
      totalCollected
      totalFemmesEtMineursDeclared
      totalFemmeEtMineursCollected
    }
  }
`;

export const PRISON_AGGREGATED_BY_REGION = gql`
  query {
      prisonAggragatedByRegion {
        region
        totalPersonnel
        totalFemme
        totalDetenus
        totalFemmeDetenus
        totalMineursDetenus
    }
  }
`;

export const PRISON_AGGREGATED_BY_PREFECTURE = gql`
  query {
      prisonAggragatedByPrefecture {
        prefecture
        totalPersonnel
        totalFemme
        totalDetenus
        totalFemmeDetenus
        totalMineursDetenus
    }
  }
`;

export const PRISONIER_AGGREGATED_BY_REGION = gql`
  query {
    prisonierAggregatedByRegion {
      region
      totalPrisoniers
      totalFemmes
      totalMineurs
      totalFemmeMineurs
      totalFemmeMineurMaries
      totalFemmeMineurAyantEnfants
      totalNonJuges
      totalFemmeNonJuges
      totalMineursNonJuges
      totalFemmeMineursNonJuges
      totalNonCondamnes
      totalFemmeNonCondamnes
      totalFemmeMineursNonCondamnes
      totalNonAccessSoins
      totalNonAccessKits
      totalNonAccessAvocats
      totalNonAccessVisites
      totalVictimeViolences
      totalFemmeVictimeViolences
      totalMineursVictimeViolences
      totalFemmeMineursVictimeViolences
    }
  }
`;

export const PRISONIER_AGGREGATED_BY_PREFECTURE = gql`
  query {
    prisonierAggregatedByPrefecture {
      prefecture
      totalPrisoniers
      totalFemmes
      totalMineurs
      totalFemmeMineurs
      totalFemmeMineurMaries
      totalFemmeMineurAyantEnfants
      totalNonJuges
      totalMineursNonJuges
      totalFemmeNonJuges
      totalFemmeMineursNonJuges
      totalNonCondamnes
      totalFemmeNonCondamnes
      totalFemmeMineursNonCondamnes
      totalNonAccessSoins
      totalNonAccessKits
      totalNonAccessAvocats
      totalNonAccessVisites
      totalVictimeViolences
      totalFemmeVictimeViolences
      totalMineursVictimeViolences
      totalFemmeMineursVictimeViolences
    }
  }
`;

export const PRISONIER_AGGREGATED_BY_PRISON = gql`
  query {
    prisonierAggregatedByPrison {
      region
      prefecture
      prison
      totalPrisoniers
      totalFemmes
      totalMineurs
      totalFemmeMineurs
      totalFemmeMineurMaries
      totalFemmeMineurAyantEnfants
      totalNonJuges
      totalMineursNonJuges
      totalFemmeNonJuges
      totalFemmeMineursNonJuges
      totalNonCondamnes
      totalFemmeNonCondamnes
      totalFemmeMineursNonCondamnes
      totalNonAccessSoins
      totalNonAccessKits
      totalNonAccessAvocats
      totalNonAccessVisites
      totalVictimeViolences
      totalFemmeVictimeViolences
      totalMineursVictimeViolences
      totalFemmeMineursVictimeViolences
    }
  }
`;

export const CHEF_ACCUSATION = gql`
  query {
    chefAccusation {
    name
    weight
  }
}
`;

export const NOURRITURES = gql`
 query {
  nourriture {
    name
    weight
  }
}
`;

export const BESOINS = gql`
 query	 {
  besoin {
    name
    weight
  } 
}
`;
